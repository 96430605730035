/*
-----------------------------------------------------------------------
	Department CSS
-----------------------------------------------------------------------
*/
.department-area {
  padding: 40px 0 125px;
  border-bottom: 1px solid #e5e5e5;
  @media #{$lg-device} {
    padding: 40px 0 100px;
  }
  @media #{$md-device} {
    padding: 15px 0 100px;
  }
  @media #{$sm-device} {
    padding: 15px 0 90px;
  }
  @media #{$xs-device} {
    padding: 15px 0 80px;
  }
  .container {
    @media #{$lg-device} {
      max-width: none;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.department-wrpp {
  display: flex;
  justify-content: space-between;
  @media #{$lg-device} {
    justify-content: center;
  }
  @media #{$md-device} {
    justify-content: normal;
    flex-direction: column-reverse;
  }

  .department-content {
    max-width: 800px;
    width: 100%;
    @media #{$lg-device} {
      max-width: 700px;
    }
    @media (max-width: 1023px) {
      max-width: 669px;
    }
    @media #{$md-device} {
      margin-bottom: 80px;
      max-width: none;
    }
  }

  .sidebar-wrapper {
    margin-top: 20px;
    max-width: 300px;
    width: 100%;
    @media #{$lg-device} {
      margin-right: 30px;
      max-width: 265px;
    }
    @media #{$md-device} {
      max-width: none;
    }
    .widget-item {
      .widget-side-nav {
        margin-bottom: 50px;
        ul {
          margin-bottom: 0;
          li {
            margin-bottom: 10px;
            a {
              background-color: $white-f8;
              border-radius: 3px;
              color: #747474;
              display: block;
              font-size: 16px;
              letter-spacing: 0;
              padding: 11.5px 33px 11.5px 63px;
              position: relative;
              &:hover,
              &.active {
                background-color: #4ca998;
                color: $white;
                &:before {
                  color: $white;
                }
              }
              &:before {
                color: #747474;
                content: "\eab8";
                font-family: "IcoFont";
                font-size: 16px;
                position: absolute;
                right: 30px;
                @include transition(0.3s);
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .widget-work-hours {
        background-color:  #4ca998;
        border-radius: 3px;
        letter-spacing: 0;
        margin-bottom: 50px;
        padding: 48px 30px 45px;
        .title {
          color: $white;
          font-size: 18px;
          margin-bottom: 31px;
          text-transform: uppercase;
        }
        ul {
          margin-bottom: 41px;
          li {
            color: $white;
            display: flex;
            font-size: 14px;
            justify-content: space-between;
            margin-bottom: 10px;
            position: relative;
            &:before {
              border-bottom: 1px dashed $white;
              bottom: 7px;
              content: "";
              left: 0;
              position: absolute;
              width: 100%;
              z-index: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .work-day {
          background-color: #4ca998;
          position: relative;
          z-index: 1;
        }
        .work-hours {
          background-color: #4ca998;
          position: relative;
          z-index: 1;
        }
        .btn-theme {
          &.btn-white {
            background-color: $white;
            border-radius: 3px;
            color: $black;
            display: block;
            font-size: 14px;
            font-weight: $font-weight-bold;
            letter-spacing: 0;
            min-height: auto;
            padding: 8px 20px;
            text-align: center;
            text-transform: uppercase;
            @media #{$lg-device} {
              font-size: 13px;
              padding: 8px 6px;
            }
            &:hover {
              background-color: $black;
              border-color: $black;
              color: $white;
            }
          }
        }
      }
      .widget-appointment-form {
        background-color: $white-f2;
        padding: 48px 30px 50px;
        .title {
          color: $black;
          font-size: 18px;
          margin-bottom: 37px;
          text-transform: uppercase;
          @media #{$lg-device} {
            font-size: 16px;
          }
        }
        form {
          .form-group {
            font-size: 14px;
            letter-spacing: 0;
            margin-bottom: 15px;
            .form-control {
              background-color: $white;
              border: none;
              border-radius: 3px;
              color: $black;
              font-size: 14px;
              height: 46px;
              padding: 12px 20px;
              width: 100%;
              @include placeholder {
                color: $black-888;
                font-size: 13px;
              }
              &:focus {
                border: none;
                box-shadow: none;
                outline: none;
              }
            }
            p{
              color: red;
            }
            textarea {
              background-color: $white;
              border: none;
              border-radius: 3px;
              font-size: 14px;
              min-height: 150px;
              padding: 12px 20px;
              width: 100%;
            }
            .btn-theme {
              border-radius: 3px;
              font-size: 14px;
              font-weight: $font-weight-bold;
              letter-spacing: 0.25px;
              line-height: 32px;
              margin-top: 22px;
              padding: 8px 30px;
              text-transform: uppercase;
              width: 100%;
            }
            &.datepicker-group {
              position: relative;
              label {
                width: 100%;
                &::before{
                  position: absolute;
                  top: 23px;
                  right: 20px;
                  transform: translateY(-50%);
                }
                input{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.department-content {
  .title {
    font-size: 48px;
    font-weight: $font-weight-light;
    letter-spacing: -1.377px;
    line-height: 1.1;
    margin-bottom: 34px;
    display: inline-block;
    @media #{$lg-device} {
      font-size: 40px;
    }
    @media #{$sm-device} {
      font-size: 36px;
    }
    @media #{$xs-device} {
      font-size: 30px;
    }
    span {
      font-weight: $font-weight-bold;
      color: #4ca998;
    }
  }
  .department-gallery {
    margin-bottom: 53px;
    @media #{$sm-device} {
      margin-bottom: 30px;
    }
    .gallery-slider {
      .swiper-slide {
        img {
          border-radius: 2px;
          width: 100%;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      background-color: rgba($black, 0.2);
      border-radius: 3px;
      color: $white;
      font-size: 18px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      width: 38px;
      @include transition(0.3s);
      &:after {
        display: none;
      }
      &:hover {
        background-color: #4ca998;
      }
    }
    .swiper-button-prev {
      left: 30px;
      @media #{$xs-device} {
        left: 15px;
      }
    }
    .swiper-button-next {
      right: 30px;
      @media #{$xs-device} {
        right: 15px;
      }
    }
  }
  .content {
    margin-bottom: 43px;
    padding: 0 18px 0 2px;
    p {
      color: $black-888;
      margin-bottom: 24px;
    }
    ul {
      margin-bottom: 0;
      li {
        color: $black-888;
        line-height: 36px;
      }
      &.list-style1 {
        padding-top: 1px;
        li {
          padding-left: 32px;
          position: relative;
          &:before {
            color: $black-888;
            content: "\ea6d";
            font-family: "IcoFont";
            left: 0;
            position: absolute;
            top: 2px;
          }
        }
      }
    }
  }
  .price-list-col2 {
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    margin-bottom: 79px;
    padding-bottom: 67px;
    @media #{$md-device} {
      justify-content: flex-start;
    }
    @media #{$sm-device} {
      margin-bottom: 49px;
      padding-bottom: 37px;
    }
    @media #{$xs-device} {
      display: block;
    }
    .price-list {
      max-width: 373px;
      width: 100%;
      &:last-child {
        max-width: 362px;
        @media #{$lg-device} {
          margin-left: 30px;
        }
        @media #{$xs-device} {
          margin-left: 0;
          margin-top: 50px;
        }
      }
    }
  }
  .price-list {
    h4 {
      color: $black;
      font-size: 24px;
      font-weight: $font-weight-normal;
      letter-spacing: -0.25px;
      margin-bottom: 40px;
    }
    ul {
      li {
        color: $body-color;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 26px;
        position: relative;
        &:before {
          border-bottom: 1px dashed $body-color;
          bottom: 2px;
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
          z-index: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .service {
          &-name {
            background-color: $white;
            position: relative;
            z-index: 1;
          }
          &-price {
            background-color: $white;
            color: $theme-color5;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
  .team-single {
    border-bottom: 0px solid #eaeaea;
    margin-bottom: 7px;
    padding-bottom: 8px;
    @media (max-width: 702px) {
      margin-bottom: 49px;
      padding-bottom: 63px;
    }
  }
  .faq-area {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 70px;
    padding-bottom: 65px;
    @media #{$sm-device} {
      margin-bottom: 60px;
      padding-bottom: 55px;
    }
    .title {
      font-size: 36px;
      letter-spacing: -1.04px;
      margin-bottom: 65px;
      @media #{$sm-device} {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
  }
  .case-area {
    .section-title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 63px;
      @media #{$sm-device} {
        margin-bottom: 35px;
      }
      .title {
        font-size: 36px;
        letter-spacing: -1.04px;
        margin-bottom: 0;
        @media #{$sm-device} {
          font-size: 30px;
        }
      }
      .btn-link {
        color: #4ca998;
        font-size: 14px;
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: 1;
        position: relative;
        text-transform: uppercase;
        top: -1px;
        @media #{$xxs-device} {
          font-size: 11px;
        }
        &:before {
          background-color: #94c3c3;
          bottom: -2px;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          width: 100%;
        }
        &:after {
          background-color: $theme-color5;
          bottom: -4px;
          content: "";
          height: 1px;
          left: 3px;
          position: absolute;
          width: calc(100% - 3px);
        }
      }
    }
    .case-col2 {
      display: flex;
      justify-content: space-between;
      .case-item {
        max-width: 385px;
        width: 100%;
      }
    }
  }
}

.team-single {
  position: relative;
  .title {
    font-size: 36px;
    letter-spacing: -1.04px;
    margin-bottom: 50px;
    @media #{$sm-device} {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
  .membr-info {
    letter-spacing: 0;
    max-width: 394px;
    width: 100%;
    @media (max-width: 702px) {
      max-width: none;
    }
    .name {
      color: #2b2b2b;
      font-size: 22px;
      letter-spacing: 0.25px;
      margin-bottom: 13px;
    }
    .designation {
      color: $black-888;
      font-size: 16px;
      font-weight: $font-weight-normal;
      margin-bottom: 40px;
    }
    .desc {
      p {
        color: $black-888;
        margin-bottom: 28px;
      }
      ul {
        margin-bottom: 52px;
        li {
          color: $black-888;
          display: flex;
          font-size: 13px;
          justify-content: space-between;
          line-height: 1;
          margin-bottom: 22px;
          position: relative;
          &:before {
            border-bottom: 0px dashed $black-888;
            bottom: 3px;
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          span {
            background-color: $white;
            position: relative;
            z-index: 1;
            &:last-child {
              color: #2b2b2b;
              font-weight: $font-weight-semi-bold;
            }
          }
        }
      }
      .btn-link {
        background-color: transparent;
        border: none;
        color: #4ca998;
        font-weight: $font-weight-bold;
        letter-spacing: 1.1371px;
        padding: 0;
        text-transform: uppercase;
        &:hover {
          color:#4ca998;
        }
      }
    }
  }
  .thumb {
    bottom: 104px;
    position: absolute;
    right: 23px;
    @media (max-width: 702px) {
      display: none;
    }
    img {
      max-width: none;
    }
  }
}
